import { Entity } from '@rest-hooks/rest';
import { SingletonEntity } from 'api/resources/Base/Singleton';

/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type Sessionid = number;
export type Expsessionpk = number;
export type Beamlinesetupid = number;
export type Proposalid = number;
export type Projectcode = string;
export type Startdate = string;
export type Enddate = string;
export type Beamlinename = string;
export type Scheduled = number;
export type Nbshifts = number;
export type Comments = string;
export type Beamlineoperator = string;
export type VisitNumber = number;
export type Bltimestamp = string;
export type Usedflag = number;
export type Sessiontitle = string;
export type Structuredeterminations = number;
export type Dewartransport = number;
export type Databackupfrance = number;
export type Databackupeurope = number;
export type Operatorsitenumber = string;
export type Lastupdate = string;
export type Protecteddata = string;
export type Externalid = string;
export type Nbreimbdewars = number;
export type Beamlinesetupid1 = number;
export type Synchrotronmode = string;
export type Undulatortype1 = string;
export type Undulatortype2 = string;
export type Undulatortype3 = string;
export type Focalspotsizeatsample = number;
export type Focusingoptic = string;
export type Beamdivergencehorizontal = number;
export type Beamdivergencevertical = number;
export type Polarisation = number;
export type Monochromatortype = string;
export type Setupdate = string;
export type Synchrotronname = string;
export type Maxexptimeperdatacollection = number;
export type Minexposuretimeperimage = number;
export type Goniostatmaxoscillationspeed = number;
export type Goniostatminoscillationwidth = number;
export type Mintransmission = number;
export type Cs = number;
export type Recordtimestamp = string;

export interface SessionResponse {
  sessionId: Sessionid;
  expSessionPk?: Expsessionpk;
  beamLineSetupId?: Beamlinesetupid;
  proposalId: Proposalid;
  projectCode?: Projectcode;
  startDate?: Startdate;
  endDate?: Enddate;
  beamLineName?: Beamlinename;
  scheduled?: Scheduled;
  nbShifts?: Nbshifts;
  comments?: Comments;
  beamLineOperator?: Beamlineoperator;
  visit_number?: VisitNumber;
  bltimeStamp: Bltimestamp;
  usedFlag?: Usedflag;
  sessionTitle?: Sessiontitle;
  structureDeterminations?: Structuredeterminations;
  dewarTransport?: Dewartransport;
  databackupFrance?: Databackupfrance;
  databackupEurope?: Databackupeurope;
  operatorSiteNumber?: Operatorsitenumber;
  lastUpdate?: Lastupdate;
  protectedData?: Protecteddata;
  externalId?: Externalid;
  nbReimbDewars?: Nbreimbdewars;
  BeamLineSetup?: BeamLineSetup;
}
export interface BeamLineSetup {
  beamLineSetupId: Beamlinesetupid1;
  synchrotronMode?: Synchrotronmode;
  undulatorType1?: Undulatortype1;
  undulatorType2?: Undulatortype2;
  undulatorType3?: Undulatortype3;
  focalSpotSizeAtSample?: Focalspotsizeatsample;
  focusingOptic?: Focusingoptic;
  beamDivergenceHorizontal?: Beamdivergencehorizontal;
  beamDivergenceVertical?: Beamdivergencevertical;
  polarisation?: Polarisation;
  monochromatorType?: Monochromatortype;
  setupDate?: Setupdate;
  synchrotronName?: Synchrotronname;
  maxExpTimePerDataCollection?: Maxexptimeperdatacollection;
  minExposureTimePerImage?: Minexposuretimeperimage;
  goniostatMaxOscillationSpeed?: Goniostatmaxoscillationspeed;
  goniostatMinOscillationWidth?: Goniostatminoscillationwidth;
  minTransmission?: Mintransmission;
  CS?: Cs;
  recordTimeStamp: Recordtimestamp;
}


export abstract class SessionResponseBase extends Entity {
  sessionId: Sessionid;
  expSessionPk?: Expsessionpk;
  beamLineSetupId?: Beamlinesetupid;
  proposalId: Proposalid;
  projectCode?: Projectcode;
  startDate?: Startdate;
  endDate?: Enddate;
  beamLineName?: Beamlinename;
  scheduled?: Scheduled;
  nbShifts?: Nbshifts;
  comments?: Comments;
  beamLineOperator?: Beamlineoperator;
  visit_number?: VisitNumber;
  bltimeStamp: Bltimestamp;
  usedFlag?: Usedflag;
  sessionTitle?: Sessiontitle;
  structureDeterminations?: Structuredeterminations;
  dewarTransport?: Dewartransport;
  databackupFrance?: Databackupfrance;
  databackupEurope?: Databackupeurope;
  operatorSiteNumber?: Operatorsitenumber;
  lastUpdate?: Lastupdate;
  protectedData?: Protecteddata;
  externalId?: Externalid;
  nbReimbDewars?: Nbreimbdewars;
  BeamLineSetup?: BeamLineSetup;
}

export abstract class SessionResponseSingletonBase extends SingletonEntity {
  sessionId: Sessionid;
  expSessionPk?: Expsessionpk;
  beamLineSetupId?: Beamlinesetupid;
  proposalId: Proposalid;
  projectCode?: Projectcode;
  startDate?: Startdate;
  endDate?: Enddate;
  beamLineName?: Beamlinename;
  scheduled?: Scheduled;
  nbShifts?: Nbshifts;
  comments?: Comments;
  beamLineOperator?: Beamlineoperator;
  visit_number?: VisitNumber;
  bltimeStamp: Bltimestamp;
  usedFlag?: Usedflag;
  sessionTitle?: Sessiontitle;
  structureDeterminations?: Structuredeterminations;
  dewarTransport?: Dewartransport;
  databackupFrance?: Databackupfrance;
  databackupEurope?: Databackupeurope;
  operatorSiteNumber?: Operatorsitenumber;
  lastUpdate?: Lastupdate;
  protectedData?: Protecteddata;
  externalId?: Externalid;
  nbReimbDewars?: Nbreimbdewars;
  BeamLineSetup?: BeamLineSetup;
}

export abstract class BeamLineSetupBase extends Entity {
  beamLineSetupId: Beamlinesetupid1;
  synchrotronMode?: Synchrotronmode;
  undulatorType1?: Undulatortype1;
  undulatorType2?: Undulatortype2;
  undulatorType3?: Undulatortype3;
  focalSpotSizeAtSample?: Focalspotsizeatsample;
  focusingOptic?: Focusingoptic;
  beamDivergenceHorizontal?: Beamdivergencehorizontal;
  beamDivergenceVertical?: Beamdivergencevertical;
  polarisation?: Polarisation;
  monochromatorType?: Monochromatortype;
  setupDate?: Setupdate;
  synchrotronName?: Synchrotronname;
  maxExpTimePerDataCollection?: Maxexptimeperdatacollection;
  minExposureTimePerImage?: Minexposuretimeperimage;
  goniostatMaxOscillationSpeed?: Goniostatmaxoscillationspeed;
  goniostatMinOscillationWidth?: Goniostatminoscillationwidth;
  minTransmission?: Mintransmission;
  CS?: Cs;
  recordTimeStamp: Recordtimestamp;
}

export abstract class BeamLineSetupSingletonBase extends SingletonEntity {
  beamLineSetupId: Beamlinesetupid1;
  synchrotronMode?: Synchrotronmode;
  undulatorType1?: Undulatortype1;
  undulatorType2?: Undulatortype2;
  undulatorType3?: Undulatortype3;
  focalSpotSizeAtSample?: Focalspotsizeatsample;
  focusingOptic?: Focusingoptic;
  beamDivergenceHorizontal?: Beamdivergencehorizontal;
  beamDivergenceVertical?: Beamdivergencevertical;
  polarisation?: Polarisation;
  monochromatorType?: Monochromatortype;
  setupDate?: Setupdate;
  synchrotronName?: Synchrotronname;
  maxExpTimePerDataCollection?: Maxexptimeperdatacollection;
  minExposureTimePerImage?: Minexposuretimeperimage;
  goniostatMaxOscillationSpeed?: Goniostatmaxoscillationspeed;
  goniostatMinOscillationWidth?: Goniostatminoscillationwidth;
  minTransmission?: Mintransmission;
  CS?: Cs;
  recordTimeStamp: Recordtimestamp;
}

