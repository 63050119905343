import { Entity } from '@rest-hooks/rest';
import { SingletonEntity } from 'api/resources/Base/Singleton';

/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type Givenname = string;
export type Familyname = string;
export type Login = string;
export type Permissions = string[];
export type Personid = number;
export type Beamlinegroups = string[];
export type Beamlines = string[];

export interface CurrentUser {
  givenName?: Givenname;
  familyName?: Familyname;
  login: Login;
  Permissions: Permissions;
  personId: Personid;
  beamLineGroups: Beamlinegroups;
  beamLines: Beamlines;
}


export abstract class CurrentUserBase extends Entity {
  givenName?: Givenname;
  familyName?: Familyname;
  login: Login;
  Permissions: Permissions;
  personId: Personid;
  beamLineGroups: Beamlinegroups;
  beamLines: Beamlines;
}

export abstract class CurrentUserSingletonBase extends SingletonEntity {
  givenName?: Givenname;
  familyName?: Familyname;
  login: Login;
  Permissions: Permissions;
  personId: Personid;
  beamLineGroups: Beamlinegroups;
  beamLines: Beamlines;
}

