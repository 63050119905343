import { Entity } from '@rest-hooks/rest';
import { SingletonEntity } from 'api/resources/Base/Singleton';

/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type Name = string;
export type Plugins = PluginConfig[];

export interface AuthConfig {
  plugins: Plugins;
}
export interface PluginConfig {
  name: Name;
  config: Config;
}
export interface Config {
}


export abstract class AuthConfigBase extends Entity {
  plugins: Plugins;
}

export abstract class AuthConfigSingletonBase extends SingletonEntity {
  plugins: Plugins;
}

export abstract class PluginConfigBase extends Entity {
  name: Name;
  config: Config;
}

export abstract class PluginConfigSingletonBase extends SingletonEntity {
  name: Name;
  config: Config;
}

export abstract class ConfigBase extends Entity {
}

export abstract class ConfigSingletonBase extends SingletonEntity {
}

